import { useEffect, useRef, useState } from 'react';

/**
 * @param {object} props
 * @param {?import('video.js').VideoJsPlayer} props.camPlayer
 * @param {(isStreaming: boolean) => void} [props.onToggleStreaming]
 * @param {(showTimeout: boolean) => void} props.setShowTimeout
 * @param {() => void} props.setupPlayer
 * @param {string} props.streamUrl
 * @param {() => void} props.trackPause
 * @param {() => void} props.trackPlay
 */
export const useCamPlayback = ({
  camPlayer,
  setupPlayer,
  streamUrl,
  setShowTimeout,
  onToggleStreaming,
  trackPlay,
  trackPause,
}) => {
  const [isStreaming, setStreaming] = useState(false);
  const isStreamingRef = useRef(false);

  useEffect(() => {
    const toggleStreaming = () => {
      // This is to defend against a race condition where the player is
      // paused again by the time the event handler is called.
      const isCurrentlyPaused = camPlayer?.paused() ?? true;
      if (isStreamingRef.current && isCurrentlyPaused) {
        isStreamingRef.current = false;
        setStreaming(false);
      }
      if (!isStreamingRef.current && !isCurrentlyPaused) {
        isStreamingRef.current = true;
        setStreaming(true);
      }
    };

    const onPlay = () => {
      setShowTimeout(false);
      toggleStreaming();
    };

    const onPause = () => {
      toggleStreaming();
    };

    camPlayer?.on('timeupdate', onPlay);
    camPlayer?.on('playing', onPlay);
    camPlayer?.on('play', onPlay);
    camPlayer?.on('play', trackPlay);
    camPlayer?.on('pause', onPause);
    camPlayer?.on('pause', trackPause);
    camPlayer?.on('ended', onPause);

    camPlayer?.one('dispose', () => {
      setStreaming(false);
      camPlayer?.off('play', onPlay);
      camPlayer?.off('play', trackPlay);
      camPlayer?.off('timeupdate', onPlay);
      camPlayer?.off('playing', onPlay);
      camPlayer?.off('pause', onPause);
      camPlayer?.off('pause', trackPause);
      camPlayer?.off('ended', onPause);
    });
    // We only want to renew these hooks when we have a new cam player
    // since it will add a new callback to the cam player every single time
    // `on()` is called.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camPlayer]);

  // Reset the player if the stream URL changes
  useEffect(() => {
    if (streamUrl) {
      setShowTimeout(false);
      setStreaming(false);
      setupPlayer();
    }
  }, [streamUrl, setupPlayer, setShowTimeout]);

  useEffect(() => {
    /* istanbul ignore else */
    if (onToggleStreaming) {
      onToggleStreaming(isStreaming);
    }
  }, [isStreaming, onToggleStreaming]);

  return isStreaming;
};
