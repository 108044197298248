/* global document */
import fscreen from 'fscreen';
import { useEffect, useState, useCallback } from 'react';
import { useEventListener } from './useEventListener';

export const useCamFullscreen = (refObj) => {
  const ref = refObj?.current;
  const [isPlayerFullscreen, setIsPlayerFullscreen] = useState(false);

  const toggleFullScreen = useCallback(
    async (event) => {
      event?.stopPropagation();
      event?.preventDefault();
      if (fscreen.fullscreenElement !== ref) {
        // this prevents the state change when there's an error (doesn't work on safari though)
        await fscreen.requestFullscreen(ref);
        setIsPlayerFullscreen(true);
      } else {
        await fscreen.exitFullscreen();
        setIsPlayerFullscreen(false);
      }
    },
    [ref],
  );

  const onFullscreenChange = useCallback(() => {
    setIsPlayerFullscreen(fscreen.fullscreenElement === ref);
  }, [ref, setIsPlayerFullscreen]);
  useEventListener(onFullscreenChange, 'fullscreenchange', fscreen);

  return { isPlayerFullscreen, toggleFullScreen, setIsPlayerFullscreen };
};

export const useFullScreenButtonOverride = ({ camRef, playerReady, toggleFullScreen }) => {
  useEffect(() => {
    // Important: fscreen doesn't work on iOS due to the fullscreen API not being fully supported.
    // We'll only override the full screen behavior when fscreen.fullscreenEnabled is true because
    // the native support still works on iOS.
    if (playerReady && document && fscreen?.fullscreenEnabled === true) {
      const btn = camRef?.current?.getElementsByClassName('vjs-fullscreen-control')?.item(0);
      if (btn) {
        const parent = btn.parentNode;
        parent.removeChild(btn);
        const newButton = document.createElement('div');
        parent.appendChild(newButton);
        newButton.classList = btn.classList;
        newButton.innerHTML = btn.innerHTML;
        newButton.onclick = toggleFullScreen;
      }
    }
  }, [camRef, playerReady, toggleFullScreen]);
};
