import { fetchPopularSpots } from 'common/api/spot';
import { doFetch, createParamString } from 'common/baseFetch';
import type { UserFavoritesResponse } from 'types/userFavorites';
import { slugify } from '@surfline/web-common';
import { UserCustomLocationsResponse } from 'types/userCustomLocations';

export const getLatestRewind = async (alias: string) => {
  const endDate = new Date();
  const endTimestampInMs = endDate.getTime();
  const startTimestampInMs = endTimestampInMs - 30 * 60 * 1000; // 30 mins

  const data = (await doFetch(
    `/cameras/recording/${alias}?startDate=${startTimestampInMs}&endDate=${endTimestampInMs}`,
  )) as any;

  return data.length > 0 ? data[0].recordingUrl : null;
};

const favoritesFetch = async (observationClarity?: boolean): Promise<UserFavoritesResponse> => {
  const queryParams: Record<string, any> = {};

  // Only include observationClarity when it's true
  if (observationClarity === true) {
    queryParams.observationClarity = true;
  }

  const url = queryParams.observationClarity
    ? `/kbyg/favorites?${createParamString(queryParams)}`
    : '/kbyg/favorites?';

  const data = (await doFetch(url)) as UserFavoritesResponse;
  return {
    data: {
      favorites: data.data.favorites,
    },
    associated: data.associated,
  };
};

export const getPopularSpots = async (
  countryCode?: string,
  observationClarity?: boolean,
): Promise<UserFavoritesResponse> => {
  // Explicitly set observationClarity to false when it's undefined
  const clarityValue = observationClarity === true;
  const { associated, data } = await fetchPopularSpots(countryCode, clarityValue);
  return {
    data: {
      favorites: data?.spots?.map((srv) => ({
        ...srv,
        subregionName: srv.subregion.name,
        slug: slugify(srv.subregion.name),
        wind: { ...srv.wind, directionType: '' },
      })),
    },
    associated,
  };
};

export const customLocationFetch = async (): Promise<UserCustomLocationsResponse> => {
  const data = (await doFetch('/favorites/locations?')) as UserCustomLocationsResponse;
  return {
    favorites: data.favorites,
  };
};

export default favoritesFetch;
